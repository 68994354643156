import React from "react";

import "../../styles/Education.css";

class Education extends React.Component {
    render() {
        return (
            <div className="educationPageContainer">
                <div className="educationContainer">
                    <h1 className="educationTitle">
                        <span className="educationInstitution">
                            University of California, Davis
                        </span>

                        <span className="educationDates">
                            2014-2018
                        </span>
                    </h1>

                    <div className="educationDescription">
                        <div className="educationFocusItem">
                            Bachelor of Arts, History Major
                        </div>

                        <div className="educationFocusItem">
                            Minor in Computer Science
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Education;