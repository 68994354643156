import React from "react";
import {Link, withRouter} from "react-router-dom";

import "../styles/Header.css";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: this.getCurrentTab()
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let previousLocationPath = prevProps.location.pathname;
        let currentLocationPath = this.props.location.pathname;

        if (previousLocationPath !== currentLocationPath) {
            this.setState({
                currentTab: this.getCurrentTab()
            });
        }
    }

    getCurrentTab() {
        let currentLocationPath = this.props.location.pathname;

        if (currentLocationPath === "/") {
            return "about";
        }
        else if (currentLocationPath === "/experience/" || currentLocationPath === "/experience") {
            return "experience";
        }
        else if (currentLocationPath === "/education/" || currentLocationPath === "/education") {
            return "education";
        }
        else if (currentLocationPath === "/resume/" || currentLocationPath === "/resume") {
            return "resume";
        }
        else if (currentLocationPath === "/contact/" || currentLocationPath === "/contact") {
            return "contact";
        }
    }

    getTabClassName(tab) {
        if (tab === this.state.currentTab) {
            return "selectedSectionTab";
        }
        else {
            return "sectionTab";
        }
    }

    render() {
        return (
            <div className="headerContainer">
                <h1 className="mainTitle">
                    Drew Berra
                </h1>

                <h2 className="subTitle">
                    Software Engineer
                </h2>

                <div className="lowerHeaderContainer">
                    <span className="sectionTabSpacer"/>

                    <span className="sectionTabsContainer">
                        <Link
                            className={this.getTabClassName("about")}
                            to={"/"}
                        >
                            About
                        </Link>

                        <Link
                            className={this.getTabClassName("experience")}
                            to={"/experience/"}
                        >
                            Experience
                        </Link>

                        <Link
                            className={this.getTabClassName("education")}
                            to={"/education/"}
                        >
                            Education
                        </Link>

                        <Link
                            className={this.getTabClassName("contact")}
                            to={"/contact/"}
                        >
                            Contact
                        </Link>
                    </span>

                    <span className="sectionTabSpacer"/>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);