import React from "react";

import ProfilePic from "../ProfilePic";

import "../../styles/LandingPage.css";

class LandingPage extends React.Component {
    render() {
        return (
            <div className="landingPageBodyContainer">
                <ProfilePic/>

                <p className="bodyParagraph">
                    I am a Software Engineer based in Pasadena, California. I have lived and worked in Southern California since I graduated from the University of California, Davis in 2018.
                </p>

                <p className="bodyParagraph">
                    I have been working as the primary Software Engineer for Net Consensus, Inc. since January 1st of 2019 in order to bring <a href="https://www.upquest.com/" className="upquestLink">UpQuest</a> from conception to production. My responsibilities at Net Consensus, Inc. include building and maintaining both the frontend and backend of our web application, managing all of the servers and related computing resources powered by aws, and communicating progress and workshopping new ideas with the CEO of our company.
                </p>

                <p className="bodyParagraph">
                    Outside of work, my interests include reading books in a variety of genres, learning new programming languages, playing Video Games, and general tinkering and making. Volunteer work is also a very important aspect of my life, and I always enjoy finding ways to work with organizations that give back to the community that I am a part of.
                </p>
            </div>
        )
    }
}

export default LandingPage;