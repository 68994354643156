import {BrowserRouter, Switch, Route} from "react-router-dom";

import Header from "./Componenets/Header";
import LandingPage from "./Componenets/Pages/LandingPage";
import Experience from "./Componenets/Pages/Experience";
import Education from "./Componenets/Pages/Education";
import ContactInfo from "./Componenets/Pages/ContactInfo";
import NotFound from "./Componenets/Pages/NotFound";

import "./styles/General.css";

function App() {
    return (
        <div className="pageContainer">
            <BrowserRouter>
                <Header />

                <div className="mainBodyContainer">
                    <Switch>
                        <Route exact path={"/"} component={LandingPage}/>
                        <Route exact path={"/experience"} component={Experience}/>
                        <Route exact path={"/education"} component={Education}/>
                        <Route exact path={"/contact"} component={ContactInfo}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
