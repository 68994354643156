import React from "react";

import drewPic from "../media/drew_blind_donkey.jpg";

import "../styles/ProfilePic.css";

class ProfilePic extends React.Component {
    render() {
        return (
            <img
                src={drewPic}
                alt={"img of Drew"}
                className="headshot"
            />
        )
    }
}

export default ProfilePic;
