import React from "react";

import "../../styles/General.css";

class NotFound extends React.Component {
    render() {
        return (
            <div className="pageNotFoundMessageContainer">
                Oops, something seems to have gone wrong...
            </div>
        )
    }
}

export default NotFound;