import React from "react";

import {
    HIDDEN_DISPLAY_TYPE,
    ACTIVE_DIV_DISPLAY_TYPE
} from "../Helpers";

import "../../styles/Experience.css";

class Experience extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            workExperience: {
                selectedTab: "netConsensusTab"
            },
            technicalExperience: {
                selectedTab: "programmingTab"
            }
        }
    }

    onWorkExperienceTabClick = (event) => {
        event.preventDefault();

        let workExperience = this.state.workExperience;
        workExperience.selectedTab = event.target.id;
        this.setState({
            workExperience: workExperience
        });
    }

    onTechnicalExperienceTabClick = (event) => {
        event.preventDefault();

        let technicalExperience = this.state.technicalExperience;
        technicalExperience.selectedTab = event.target.id;
        this.setState({
            technicalExperience: technicalExperience
        });
    }

    onExpandableExperienceClick = (event) => {
        let sectionTitleNode = event.currentTarget;
        let idOfSubsection = sectionTitleNode.id.replace("ExperienceTitle", "Subsection");

        let subSection = document.getElementById(idOfSubsection);
        if (subSection.style.display === HIDDEN_DISPLAY_TYPE) {
            subSection.style.display = ACTIVE_DIV_DISPLAY_TYPE;
            sectionTitleNode.innerHTML = sectionTitleNode.innerHTML.replace("[+]", "[ - ]");
        }
        else {
            subSection.style.display = HIDDEN_DISPLAY_TYPE;
            sectionTitleNode.innerHTML = sectionTitleNode.innerHTML.replace("[ - ]", "[+]");
        }
    }

    getWorkExperienceTabClassName(tab) {
        if (tab === this.state.workExperience.selectedTab) {
            return "workExperienceTabSelected";
        }
        else {
            return "workExperienceTab";
        }
    }

    getTechnicalExperienceTabClassName(tab) {
        if (tab === this.state.technicalExperience.selectedTab) {
            return "workExperienceTabSelected";
        }
        else {
            return "workExperienceTab";
        }
    }

    renderWorkExperienceDescription() {
        if (this.state.workExperience.selectedTab === "citrisTab") {
            return (
                <div className="workExperienceDescriptionContainer">
                    <h2 className="workExperienceDescriptionTitle">
                        Web Developer & Data Analyst
                    </h2>

                    <div className="workExperienceDates">
                        OCT 2018 - JAN 2019
                    </div>

                    <div className="workExperienceDescriptionBody">
                        In this role I maintained and developed features for the CITRIS at UC Davis Website and helped to demonstrate the impact of projects that CITRIS funded. I accomplished the first dimension of my position by using a combination of HTML, Javascript, and CSS to release incremental improvements to our website so that we could better display the purpose of our company and showcase the projects that we were funding. In order to accomplish the second part of my job, I collaborated with Data Analysts from other campuses to parse through data and create understandable ways to demonstrate the impact of the projects that CITRIS was apart of.
                    </div>
                </div>
            )
        }
        else if (this.state.workExperience.selectedTab === "ucDavisTab") {
            return (
                <div className="workExperienceDescriptionContainer">
                    <h2 className="workExperienceDescriptionTitle">
                        Research Assistant
                    </h2>

                    <div className="workExperienceDates">
                        JAN 2015 - JUN 2015
                    </div>

                    <div className="workExperienceDescriptionBody">
                        Assisted a Biology Doctoral Candidate at UC Davis by encoding videos from discussion sections to aid in their research on how college age students learn.
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="workExperienceDescriptionContainer">
                    <h2 className="workExperienceDescriptionTitle">
                        Principal Software Engineer
                    </h2>

                    <div className="workExperienceDates">
                        JAN 2019 - Present
                    </div>

                    <div className="workExperienceDescriptionBody">
                        As the primary Software Engineer at Net Consensus, Inc. I have been working to bring our web application, <a href="https://www.upquest.com/">UpQuest</a>, from conception to production. In order to accomplish this, I use the Python framework Django and the JavaScript framework ReactJS to help me create both the backend and frontend of our web application respectively. I create robust RESTful APIs to allow the two to seamlessly communicate and I use MariaDB to power UpQuest’s Database. I am also responsible for deploying and managing all of our server and related computing solutions through aws. A project of this size is not created by one person, and I work in close collaboration with our founder and CEO to help develop the algorithms that power UpQuest and am a coinventor on a patent that is pending for UpQuests core voting algorithm. We have been assisted in this project by multiple contractors and I have been partially responsible for managing them. I am also responsible for organizing the workflow of multiple developers creating different features at one time by utilizing the version control system, git.
                    </div>
                </div>
            )
        }
    }

    renderTechnicalExperienceDescription() {
        if (this.state.technicalExperience.selectedTab === "specialSkillsTab") {
            return (
                <div className="workExperienceDescriptionContainer">
                    <div className="technicalExperienceListItem">
                        Ability to learn and teach myself new languages quickly.
                    </div>

                    <div className="technicalExperienceListItem">
                        Ability to work as part of a team and independently.
                    </div>

                    <div className="technicalExperienceListItem">
                        Has practical experience with common types of data structures and experience implementing them.
                    </div>

                    <div className="technicalExperienceListItem">
                        Familiarity with the various programming paradigms and proficient in at least one languages in each.
                    </div>

                    <div className="technicalExperienceListItem">
                        Ability to develop efficient algorithms and deduce their comparative run times.
                    </div>

                    <div className="technicalExperienceListItem">
                        Experienced with the most commonly used algorithms and familiar their comparative run times.
                    </div>
                </div>
            )
        }
        else if (this.state.technicalExperience.selectedTab === "patentsTab") {
            return (
                <div className="workExperienceDescriptionContainer">
                    <h2 className="workExperienceDescriptionTitle">
                        METHODS AND SYSTEMS FOR PREDICTIVE RANKING OF ELEMENTS <span className="workExperienceDates">Pending</span>
                    </h2>

                    <h3 className="technicalExperiencePatentSubtitle">
                        Coinventor
                    </h3>

                    <div className="workExperienceDescriptionBody">
                        Patent for the Algorithm that was developed for UpQuests core voting system, but that can be used effectively in many predictive ranking models.
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="workExperienceDescriptionContainer">
                    <h3 className="technicalExperienceSubtitle">
                        Programming Languages
                    </h3>

                    <div
                        className="technicalExperienceClickableListItem"
                    >
                        <div
                            id="pythonExperienceTitle"
                            className="technicalExperienceClickableListItemTitle"
                            onClick={this.onExpandableExperienceClick}
                        >
                            Python - 4 years [+]
                        </div>

                        <div
                            id="pythonSubsection"
                            style={{
                                display: HIDDEN_DISPLAY_TYPE
                            }}
                            className="technicalExperienceSubsection"
                        >
                            <div className="technicalExperienceSubsectionItem">
                                Django
                            </div>

                            <div className="technicalExperienceSubsectionItem">
                                Flask
                            </div>
                        </div>
                    </div>

                    <div
                        className="technicalExperienceClickableListItem"
                    >
                        <div
                            id="javascriptExperienceTitle"
                            className="technicalExperienceClickableListItemTitle"
                            onClick={this.onExpandableExperienceClick}
                        >
                            Javascript - 3 years [+]
                        </div>

                        <div
                            id="javascriptSubsection"
                            style={{
                                display: HIDDEN_DISPLAY_TYPE
                            }}
                            className="technicalExperienceSubsection"
                        >
                            ReactJS
                        </div>
                    </div>

                    <div
                        className="technicalExperienceClickableListItem"
                    >
                        <div
                            id="sqlExperienceTitle"
                            className="technicalExperienceClickableListItemTitle"
                            onClick={this.onExpandableExperienceClick}
                        >
                            SQL - 2 years [+]
                        </div>

                        <div
                            id="sqlSubsection"
                            style={{
                                display: HIDDEN_DISPLAY_TYPE
                            }}
                            className="technicalExperienceSubsection"
                        >
                            <div className="technicalExperienceSubsectionItem">
                                MariaDB
                            </div>

                            <div className="technicalExperienceSubsectionItem">
                                MySQL
                            </div>
                        </div>
                    </div>

                    <div className="technicalExperienceListItem">
                        C++ - 2 years
                    </div>

                    <div className="technicalExperienceListItem">
                        C - 1 year
                    </div>

                    <div className="technicalExperienceListItem">
                        Rust - 1 year
                    </div>

                    <div className="technicalExperienceListItem">
                        Go - under 1 year
                    </div>

                    <div className="technicalExperienceListItem">
                        HTML/CSS - 3 years
                    </div>

                    <h3 className="technicalExperienceSubtitle">
                        Cloud Computing Services
                    </h3>

                    <div className="technicalExperienceListItem">
                        aws - 2 years
                    </div>

                    <h3 className="technicalExperienceSubtitle">
                        Version Control Systems
                    </h3>

                    <div className="technicalExperienceListItem">
                        git - 5 years
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="experiencePageContainer">
                <span className="workExperienceSectionContainer">
                    <h1 className="workExperienceSectionTitle">
                        Work Experience
                    </h1>

                    <div className="workExperienceDisplayContainer">
                        <div className="workExperienceTabContainer">
                            <div
                                id="netConsensusTab"
                                className={this.getWorkExperienceTabClassName("netConsensusTab")}
                                onClick={this.onWorkExperienceTabClick}
                            >
                                Net Consensus, Inc.
                            </div>

                            <div
                                id="citrisTab"
                                className={this.getWorkExperienceTabClassName("citrisTab")}
                                onClick={this.onWorkExperienceTabClick}
                            >
                                CITRIS, UC Davis
                            </div>

                            <div
                                id="ucDavisTab"
                                className={this.getWorkExperienceTabClassName("ucDavisTab")}
                                onClick={this.onWorkExperienceTabClick}
                            >
                                UC Davis
                            </div>
                        </div>

                        {this.renderWorkExperienceDescription()}
                    </div>
                </span>

                <span className="workExperienceSectionContainer">
                    <h1 className="workExperienceSectionTitle">
                        Technical Experience
                    </h1>

                    <div className="workExperienceDisplayContainer">
                        <div className="workExperienceTabContainer">
                            <div
                                id="programmingTab"
                                className={this.getTechnicalExperienceTabClassName("programmingTab")}
                                onClick={this.onTechnicalExperienceTabClick}
                            >
                                Programming
                            </div>

                            <div
                                id="specialSkillsTab"
                                className={this.getTechnicalExperienceTabClassName("specialSkillsTab")}
                                onClick={this.onTechnicalExperienceTabClick}
                            >
                                Special Skills
                            </div>

                            <div
                                id="patentsTab"
                                className={this.getTechnicalExperienceTabClassName("patentsTab")}
                                onClick={this.onTechnicalExperienceTabClick}
                            >
                                Patents
                            </div>
                        </div>

                        {this.renderTechnicalExperienceDescription()}
                    </div>
                </span>
            </div>
        );
    }
}

export default Experience;