import React from "react";

import ProfilePic from "../ProfilePic";

import {
    HIDDEN_DISPLAY_TYPE,
    ACTIVE_INLINE_DISPLAY_TYPE
} from "../Helpers";

import "../../styles/ContactInfo.css";

class ContactInfo extends React.Component {

    onCopyEmailClick = (event) => {
        event.preventDefault();
        if (!navigator.clipboard) {
            let textArea = document.createElement("textarea");
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;

            textArea.style.width = '2em';
            textArea.style.height = '2em';

            textArea.style.padding = 0;

            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';

            textArea.style.background = 'transparent';


            textArea.value = "drew.berra@gmail.com";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let didCopyToClip = document.execCommand('copy');
                if (didCopyToClip) {
                    this.activateEmailCopySuccessIndicator();
                }
                else {
                    this.activateEmailCopyFailureIndicator();
                }
            } catch (err) {
                this.activateEmailCopyFailureIndicator();
            }

            document.body.removeChild(textArea);
        }
        else {
            navigator.clipboard.writeText("drew.berra@gmail.com").then(
                () => {
                    this.activateEmailCopySuccessIndicator();
                }
            ).catch(
                () => {
                    this.activateEmailCopyFailureIndicator();
                }
            )
        }
    }

    activateEmailCopySuccessIndicator() {
        document.getElementById("emailCopyFailureIndicator").style.display = HIDDEN_DISPLAY_TYPE;
        document.getElementById("emailCopySuccessIndicator").style.display = ACTIVE_INLINE_DISPLAY_TYPE;
    }

    activateEmailCopyFailureIndicator() {
        document.getElementById("emailCopySuccessIndicator").style.display = HIDDEN_DISPLAY_TYPE;
        document.getElementById("emailCopyFailureIndicator").style.display = ACTIVE_INLINE_DISPLAY_TYPE;
    }

    render() {
        return (
            <div className="contactInfoBody">
                <ProfilePic/>

                <div className="contactInfoContainer">
                    <div className="emailContainer">
                        Email: <a
                            href="mailto:drew.berra@gmail.com"
                            className="emailLink"
                        >
                            drew.berra@gmail.com
                        </a>

                        <i
                            className="fa fa-clipboard emailCopyIcon"
                            aria-hidden="true"
                            onClick={this.onCopyEmailClick}
                        />

                        <i
                            id="emailCopySuccessIndicator"
                            className="fa fa-check-circle emailCopySuccessIndicator"
                            style={{
                                display: HIDDEN_DISPLAY_TYPE
                            }}
                        />

                        <i
                            id="emailCopyFailureIndicator"
                            className="fa fa-times-circle emailCopyFailureIndicator"
                            style={{
                                display: HIDDEN_DISPLAY_TYPE
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactInfo;